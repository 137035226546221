import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Country } from 'types/Address';
import { ADDRESS_FORM_TYPE } from '../../../../constants/address';
import { FORM_FIELDS } from '../../../../constants/checkout';
import { formatPostalCode } from '../../../../utils/postalCodeUtil';
import DeliveryPostalCodeSelect from '../../../DeliveryPostalCodeSelect/DeliveryPostalCodeSelect';
import FormGroupInput from '../../../Form/FormGroupInput/FormGroupInput';
import GoogleMapsStreetInput from '../../../Form/GoogleMapsStreetInput/GoogleMapsStreetInput';
import Alert from '../../../Layout/Alert/Alert';
import Typography from '../../../Typography/Typography';

type AddressFormType = {
  formId?: string;
  initialCountry?: Country;
  isInvoice?: boolean;
  isNewAddress?: boolean;
  onChangeCountryCallback: () => void;
  onSubmitPostalcode: (formattedPostalCode: string, formType: string) => void;
  setAddressFieldValue: (field: string, value: string) => void;
  values?: Record<string, string>;
  withTelephone?: boolean;
};

const AddressForm = ({
  formId,
  initialCountry,
  isInvoice,
  isNewAddress,
  onChangeCountryCallback,
  onSubmitPostalcode,
  setAddressFieldValue,
  values,
  withTelephone = true,
}: AddressFormType) => {
  const { formatMessage } = useIntl();

  const initialCountryState: Country = { isocode: initialCountry?.isocode };
  const [country, setCountry] = useState(initialCountryState);

  const isAddressPrefilled = !!values?.[FORM_FIELDS.IS_ADDRESS_PREFILLED];

  const prefix = isInvoice ? ADDRESS_FORM_TYPE.INVOICE : ADDRESS_FORM_TYPE.DELIVERY;

  const formType = isInvoice ? FORM_FIELDS.INVOICE_LOCATION : FORM_FIELDS.DELIVERY_LOCATION;

  return (
    <div className="default-address-form flex flex-col">
      {!isInvoice && (
        <Typography fontWeight="normal" tag="h2" type="h3">
          {formatMessage({
            id: 'checkout_delivery_data_delivery_address',
          })}
        </Typography>
      )}
      {isNewAddress && (
        <div>
          <Alert>
            <Typography>
              {formatMessage({
                id: 'checkout_new_delivery_alert',
              })}
            </Typography>
          </Alert>
          <FormGroupInput
            id={`${formId}_address_title`}
            label={formatMessage({
              id: 'checkout_delivery_data_address',
            })}
            name={FORM_FIELDS.ADDRESS_TITLE}
          />
        </div>
      )}

      <div>
        {isAddressPrefilled && (
          <Alert>
            <Typography>
              {formatMessage({
                id: 'vies_service_prefilled_address_alert',
              })}
            </Typography>
          </Alert>
        )}
        <GoogleMapsStreetInput
          country={country}
          disabled={isAddressPrefilled}
          handleAutoFillFields={({ countryCode, street, streetNumber, town = '', zipCode = '' }) => {
            setAddressFieldValue(`${prefix}_route`, street ?? '');
            setAddressFieldValue(`${prefix}_street_number`, streetNumber ?? '');
            onSubmitPostalcode(formatPostalCode(town, zipCode, countryCode), formType);
          }}
          id={`${formId}_${prefix}_route`}
          label={formatMessage({
            id: 'my_account_address_form_street',
          })}
          name={isInvoice ? FORM_FIELDS.INVOICE_ROUTE : FORM_FIELDS.DELIVERY_ROUTE}
        />
      </div>

      <FormGroupInput
        disabled={isAddressPrefilled}
        id={`${formId}_${prefix}_street_number`}
        label={formatMessage({
          id: 'checkout_delivery_data_street_number',
        })}
        name={isInvoice ? FORM_FIELDS.INVOICE_STREET_NUMBER : FORM_FIELDS.DELIVERY_STREET_NUMBER}
        type="text"
      />

      <FormGroupInput
        disabled={isAddressPrefilled}
        id={`${formId}_${prefix}_poBox`}
        label={formatMessage({
          id: 'checkout_delivery_data_poBox',
        })}
        name={isInvoice ? FORM_FIELDS.INVOICE_POBOX : FORM_FIELDS.DELIVERY_POBOX}
        type="text"
      />

      <DeliveryPostalCodeSelect
        country={country}
        disabled={isAddressPrefilled}
        id={`${formType}_${prefix}_postalCode`}
        isCheckout={false}
        label={formatMessage({
          id: 'my_account_address_form_location',
        })}
        name={formType}
        onChangeCountryCallback={onChangeCountryCallback}
        onSubmitPostalCode={(value) => onSubmitPostalcode(value, formType)}
        setCountry={setCountry}
        withIcons={false}
      />
      {isInvoice && withTelephone && (
        <FormGroupInput
          id={`${formId}_${prefix}_phone`}
          label={formatMessage({
            id: 'checkout_delivery_data_phone',
          })}
          name={FORM_FIELDS.INVOICE_PHONE}
          type="text"
        />
      )}
    </div>
  );
};

export default AddressForm;
