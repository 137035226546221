import { Text } from 'ui';
import { cn } from 'utils/cn';

interface DividerProps {
  className?: string;
  text?: string;
}

const Divider = ({ className, text }: DividerProps) => (
  <div className={cn('relative flex flex-row items-center justify-center', className)}>
    {text && <Text className="z-base bg-white px-8 font-bold uppercase">{text}</Text>}
    <div className="absolute left-0 top-1/2 z-0 h-px w-full bg-accent-40" />
  </div>
);

export default Divider;
