import styled from 'styled-components';
import { color, spacer } from 'theme';
import Dropdown from '../../Dropdown/Dropdown';

export const StyledDropdown = styled(Dropdown)`
  z-index: 12;

  &[data-open='true'] {
    max-height: none;
    position: unset;
  }
`;

export const StyledDropdownItem = styled.div`
  padding: ${spacer(100)};

  .dropdown-item-active & {
    background-color: ${color('primary-400')};
    color: ${color('white')};
  }
`;
